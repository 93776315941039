<script>
import appConfig from "@/app.config";
import axios from "axios";
// import Swal from "sweetalert2";

export default {
    page: {
        title: "Detail Materi Uji Kompetensi",
        meta: [
            {
                name: "description",
                content: appConfig.description,
            },
        ],
    },
    components: {},
    data() {
        return {
            title: "Detail Materi Uji Kompetensi",
            items: [
                {
                    text: "Verifikasi TUK",
                    href: "/verifikasi-tuk",
                },
                {
                    text: "Detail Materi Uji Kompetensi",
                    active: true,
                },
            ],
            // Catch Error Axios
            axiosCatchError: null,

            // variabel referensi
            optionsMenuParent: [],

            //modal
            showModalSuratPenggunaan: false,

            meta_asesor: [
                {
                    'tuk': 'Ruang Meeting LSP',
                    'tanggal_asesmen': '1 Agustus 2023'
                },
            ],
            link_backend: process.env.VUE_APP_BACKEND_URL_VERSION,
            link_form: 'dokumen/files/FR APL 01'
        };
    },
    mounted() {
        // this.getDataTable();
    },
    methods: {
        addAsesor() {
            this.meta_asesor.push({
                tuk: "",
                tanggal_asesmen: "",
            });
        },
        removeAsesor(index) {
            this.meta_asesor.splice(index, 1);
        },
        modalSurat() {
            let self = this;
            self.showModalSuratPenggunaan = false;
            // config untuk re render component
            self.$nextTick(() => {
                self.showModalSuratPenggunaan = true;
            });
        },
        getDataTable(
            url = process.env.VUE_APP_BACKEND_URL_VERSION + "dokumen/files"
        ) {
            let self = this;

            var config = {
                method: "get",
                url: url,
                params: self.tableData,
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + localStorage.access_token
                },
            };

            axios(config)
                .then((response) => {
                    console.log(response);
                })
                .catch((errors) => {
                    console.log(errors);
                });
        },
        showSuratPenggunaanValidasi() {
            let self = this;
            self.$router.push({ name: "add-surat_tugas_validasi" });
        }
    },
};
</script>

<template>
    <div class="row">
        <div class="col-lg-12">
            <div class="row">
                <div class="col-md-6">
                    <div class="form-group col-md-12">
                        <label for="tipe_menu">Tanggal Surat Penggunaan</label>
                        <input type="date" name="" id="" class="form-control" value="2023-07-21">
                    </div>
                    <div class="form-group col-md-12">
                        <label for="tipe_menu">Nomor Surat Penggunaan</label>
                        <input type="text" name="" id="" class="form-control" value="0058/LSP/MUK/II/2023">
                    </div>
                    <div class="form-group col-md-12">
                        <label for="tipe_menu">Perihal</label>
                        <input type="text" name="" id="" class="form-control" value="Surat Penggunaan Penyusunan MUK">
                    </div>
                    <div class="form-group col-md-12 text-end mt-3">
                        <a :href="link_backend + link_form" target="_blank" class="btn btn-warning btn-sm"
                            style="margin-left: 3px;"><i class="fas fa-eye"></i> Lihat Surat</a>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group col-md-12">
                        <label for="tipe_menu">No. Jadwal Sertifikasi</label>
                        <input type="text" name="" id="" class="form-control">
                    </div>
                    <div class="form-group col-md-12">
                        <label for="tipe_menu">Keterangan</label>
                        <textarea name="" id="" cols="30" rows="10" class="form-control"></textarea>
                    </div>
                </div>
            </div>
            <div class="form-group col-md-12">
                <label for="tipe_menu">Daftar TUK</label>
                <table class="table mb-0 table-bordered table-condensed table-hover mt-2">
                    <thead class="bg-dark text-center text-white">
                        <tr>
                            <th>TUK</th>
                            <th>Tanggal Asesmen</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-if="meta_asesor.length == 0">
                            <td colspan="2">TIdak Ada Data</td>
                        </tr>
                        <tr v-for="(item, index) in meta_asesor" :key="index">
                            <td>
                                <input type="text" class="form-control" v-model="item.tuk">
                            </td>
                            <td>
                                <input type="text" class="form-control" v-model="item.tanggal_asesmen">
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="row">
                <div class="col-md-12 col-md-6">
                    <div class="text-end">
                        <button class="btn btn-sm btn-primary bg-primary m-1"><i class="fas fa-save"></i>
                            Simpan</button>
                        <!-- <router-link :to="{ name: 'add-verifikasi_tuk' }"
                            class="btn btn-sm btn-danger bg-danger m-1"><i class="fa fa-times"></i>
                            Tolak</router-link>
                        <router-link :to="{ name: 'add-verifikasi_tuk_detail_penggunaan' }"
                            class="btn btn-sm btn-success bg-success m-1"><i class="fa fa-check"></i>
                            Terima</router-link> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
    <b-modal v-model="showModalSuratPenggunaan" title="Surat Penggunaan Validator" title-class="text-black font-18"
        body-class="p-3" size="xl" hide-footer>
        <div class="row">
            <div class="col-lg-12">
                <div class="card">
                    <b-form class="p-2" @submit.prevent="StoreData">
                        <div class="card-body">
                            <div v-if="axiosCatchError">
                                <div class="alert alert-danger" v-if="typeof axiosCatchError === 'string' ||
                                    axiosCatchError instanceof String
                                    ">
                                    {{ axiosCatchError }}
                                </div>
                                <div class="alert alert-danger" role="alert" v-else>
                                    <div v-for="(errorArray, idx) in axiosCatchError" :key="idx">
                                        <div v-for="(allErrors, idx) in errorArray" :key="idx">
                                            <span class="text-danger">{{ allErrors[0] }} </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="text-center">
                                        <h3>Formulir Surat Penggunaan Penyusunan Materi Uji Kompetensi</h3>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="form-group col-md-12">
                                                <label for="tipe_menu">Tanggal Surat Penggunaan</label>
                                                <input type="date" name="" id="" class="form-control">
                                            </div>
                                            <div class="form-group col-md-12">
                                                <label for="tipe_menu">Nomor Surat Penggunaan</label>
                                                <input type="text" name="" id="" class="form-control">
                                            </div>
                                            <div class="form-group col-md-12">
                                                <label for="tipe_menu">Perihal</label>
                                                <input type="text" name="" id="" class="form-control">
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group col-md-12">
                                                <label for="tipe_menu">Pilih Asesor Penyusun</label>
                                                <select name="" id="" class="form-control">
                                                    <option value="">Pilih Asesor</option>
                                                    <option value="">Asesor 1</option>
                                                    <option value="">Asesor 2</option>
                                                </select>
                                            </div>
                                            <div class="form-group col-md-12">
                                                <label for="tipe_menu">Pilih Skema</label>
                                                <select name="" id="" class="form-control">
                                                    <option value="">Pilih Skema</option>
                                                    <option value="">Skema 1</option>
                                                    <option value="">Skema 2</option>
                                                </select>
                                            </div>
                                            <div class="form-group col-md-12">
                                                <label for="tipe_menu">Pilih Periode</label>
                                                <select name="" id="" class="form-control">
                                                    <option value="">Pilih Periode</option>
                                                    <option value="">Pra Asesmen</option>
                                                    <option value="">Asesmen</option>
                                                    <option value="">Pasca Asesmen</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-md-12 text-end mt-3">
                                            <button class="btn btn-primary"><i class="fas fa-save"></i> Simpan</button>
                                        </div>
                                    </div>

                                    <div class="form-group col-md-12">
                                        <label for="tipe_menu">Dokumen MUK</label>
                                        <table class="table mb-0 table-bordered table-condensed table-hover mt-2">
                                            <thead class="bg-dark text-center text-white">
                                                <tr>
                                                    <th>Dokumen</th>
                                                    <th><button type="button" class="btn btn-sm btn-success"
                                                            @click="addDokumen()"><i class="fa fa-plus"></i></button></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-if="meta_asesor.length == 0">
                                                    <td colspan="3">TIdak Ada Data</td>
                                                </tr>
                                                <tr v-for="(item, index) in meta_asesor" :key="index">
                                                    <td>
                                                        <input type="text" class="form-control" v-model="item.nama_dokumen">
                                                    </td>
                                                    <td><button type="button" class="btn btn-sm btn-danger"
                                                            @click="removeDokumen(index)"><i
                                                                class="fa fa-times"></i></button></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card-footer">
                            <div class="row">
                                <div class="col-md-12 col-md-6">
                                    <div class="text-end">
                                        <b-button type="button" class="m-1" variant="warning"><i
                                                class="fas fa-download"></i>
                                            Unduh PDF</b-button>
                                        <b-button v-on:click="modalSimpan" type="submit" variant="primary" class="m-1"><i
                                                class="fa fa-save"></i>
                                            Simpan</b-button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </b-form>
                </div>
            </div>
        </div>
    </b-modal>
</template>
