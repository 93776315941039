<script>
import appConfig from "@/app.config";
// import axios from "axios";
// import Swal from "sweetalert2";

export default {
    page: {
        title: "Detail Materi Uji Kompetensi",
        meta: [
            {
                name: "description",
                content: appConfig.description,
            },
        ],
    },
    components: {},
    data() {
        return {
            title: "Detail Materi Uji Kompetensi",
            items: [
                {
                    text: "Verifikasi TUK",
                    href: "/verifikasi-tuk",
                },
                {
                    text: "Detail Materi Uji Kompetensi",
                    active: true,
                },
            ],
            // Catch Error Axios
            axiosCatchError: null,

            // variabel referensi
            optionsMenuParent: [],

            //modal
            showModalSimpan: false,

            meta_asesor: [
                {
                    'skema_sertifikasi': 'Pelaksana Patologi dan Toksikologi',
                    'tuk': 'Ruang Meeting LSP',
                    'tanggal_asesmen': '1 Agustus 2023',
                    'asesor_verifikasi': 'KASAHANA INDRA PRATAMA'
                },
            ],
        };
    },
    mounted() {
    },
    methods: {
        addAsesor() {
            this.meta_asesor.push({
                skema_sertifikasi: "",
                tuk: "",
                tanggal_asesmen: "",
                asesor_verifikasi: "",
            });
        },
        removeAsesor(index) {
            this.meta_asesor.splice(index, 1);
        },
        modalSimpan() {
            let self = this;
            self.showModalSimpan = false;
            // config untuk re render component
            self.$nextTick(() => {
                self.showModalSimpan = true;
            });
        },
    },
};
</script>

<template>
    <div class="row">
        <div class="col-lg-12">
            <b-form class="p-2" @submit.prevent="StoreData">
                <div v-if="axiosCatchError">
                    <div class="alert alert-danger" v-if="typeof axiosCatchError === 'string' ||
                        axiosCatchError instanceof String
                        ">
                        {{ axiosCatchError }}
                    </div>
                    <div class="alert alert-danger" role="alert" v-else>
                        <div v-for="(errorArray, idx) in axiosCatchError" :key="idx">
                            <div v-for="(allErrors, idx) in errorArray" :key="idx">
                                <span class="text-danger">{{ allErrors[0] }} </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">

                    <div class="col-lg-12">
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group col-md-12">
                                    <label for="tipe_menu">Tanggal Surat Tugas</label>
                                    <input type="date" name="" id="" class="form-control">
                                </div>
                                <div class="form-group col-md-12">
                                    <label for="tipe_menu">Nomor Surat Tugas</label>
                                    <input type="text" name="" id="" class="form-control">
                                </div>
                                <div class="form-group col-md-12">
                                    <label for="tipe_menu">Perihal</label>
                                    <input type="text" name="" id="" class="form-control">
                                </div>
                                <div class="form-group col-md-12">
                                    <label for="tipe_menu">Surat Tugas</label><br>
                                    <button class="btn btn-warning btn-sm"><i class="fas fa-download"></i> Lihat
                                        Surat</button>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group col-md-12">
                                    <label for="tipe_menu">No Jadwal Sertifikasi</label>
                                    <input type="text" name="" id="" class="form-control">
                                </div>
                                <div class="form-group col-md-12">
                                    <label for="tipe_menu">Keterangan</label>
                                    <textarea name="" id="" cols="30" rows="10" class="form-control"></textarea>
                                </div>
                            </div>
                        </div>
                        <div class="form-group col-md-12">
                            <label for="tipe_menu">ASESOR VERIFIKATOR TEMPAT UJI KOMPENTENSI</label>
                            <table class="table mb-0 table-bordered table-condensed table-hover mt-2">
                                <thead class="bg-dark text-center text-white">
                                    <tr>
                                        <th>Skema Sertifikasi</th>
                                        <th>TUK</th>
                                        <th>Tanggal Asesmen</th>
                                        <th>Asesor Verifikasi</th>
                                        <th>Aksi</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-if="meta_asesor.length == 0">
                                        <td colspan="3">TIdak Ada Data</td>
                                    </tr>
                                    <tr v-for="(item, index) in meta_asesor" :key="index">
                                        <td>
                                            <input type="text" class="form-control" v-model="item.skema_sertifikasi">
                                        </td>
                                        <td>
                                            <select name="" id="" class="form-control">
                                                <option value="">Plih TUK</option>
                                                <option value="Ruang Meeting LSP">Ruang Meeting LSP</option>
                                            </select>
                                        </td>
                                        <td>
                                            <input type="text" class="form-control" v-model="item.tanggal_asesmen">
                                        </td>
                                        <td>
                                            <select name="" id="" class="form-control">
                                                <option value="">Plih Asesor</option>
                                                <option value="KASAHANA INDRA PRATAMA">KASAHANA INDRA PRATAMA</option>
                                            </select>
                                        </td>
                                        <td><button class="btn btn-sm btn-primary"><i class="fas fa-eye"></i> Lihat</button></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </b-form>
        </div>
        <div class="row">
            <div class="col-md-12 col-md-6">
                <div class="text-end">
                    <router-link :to="{ name: 'preview-penetapan_tuk' }"
                        class="btn btn-sm btn-primary bg-primary"><i class="fas fa-save"></i>
                    Simpan</router-link>
                    <!-- <router-link :to="{ name: 'add-verifikasi_tuk_detail_penggunaan' }"
                            class="btn btn-sm btn-danger bg-danger m-1"><i class="fa fa-times"></i>
                            Tolak</router-link> -->
                    <b-button v-on:click="modalSimpan" type="button" variant="success" class="m-1 btn-sm"><i
                            class="fa fa-check"></i>
                        Terima</b-button>
                </div>
            </div>
        </div>
    </div>
</template>
