<script>
import Layout from "@/router/layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import DetailPenggunaan from "./detail-penggunaan";
import DetailSuratTugas from "./detail-tugas";
// import axios from "axios";
// import Swal from "sweetalert2";

export default {
    page: {
        title: "Detail Tempat Uji Kompetensi",
        meta: [
            {
                name: "description",
                content: appConfig.description,
            },
        ],
    },
    components: {
        Layout,
        PageHeader,
        DetailPenggunaan,
        DetailSuratTugas,
    },
    data() {
        return {
            title: "Detail Tempat Uji Kompetensi",
            items: [
                {
                    text: "Verifikasi TUK",
                    href: "/verifikasi-tuk",
                },
                {
                    text: "Detail Tempat Uji Kompetensi",
                    active: true,
                },
            ],
            // Catch Error Axios
            axiosCatchError: null,

            // variabel referensi
            optionsMenuParent: [],

            //modal
            showModalSimpan: false,

            meta_asesor: [
                {
                    'tuk': 'Ruang Meeting LSP',
                    'tanggal_asesmen': '1 Agustus 2023'
                },
            ]
        };
    },
    mounted() {
    },
    methods: {
        addAsesor() {
            this.meta_asesor.push({
                tuk: "",
                tanggal_asesmen: "",
            });
        },
        removeAsesor(index) {
            this.meta_asesor.splice(index, 1);
        },
        modalSimpan() {
            let self = this;
            self.showModalSimpan = false;
            // config untuk re render component
            self.$nextTick(() => {
                self.showModalSimpan = true;
            });
        },
    },
};
</script>
<style>
.col-md-custom {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 20%;
}

.list-inline{
    margin-top: 10px;
}

.hori-timeline .events {
    border-top: 3px solid #e9ecef;
}

.hori-timeline .events .event-list {
    display: block;
    position: relative;
    text-align: center;
    padding-top: 70px;
    margin-right: 0;
}

.hori-timeline .events .event-list:before {
    content: "";
    position: absolute;
    height: 36px;
    border-right: 2px dashed #dee2e6;
    top: 0;
}

.hori-timeline .events .event-list .event-date {
    position: absolute;
    top: 38px;
    left: 0;
    right: 0;
    width: 100px;
    margin: 0 auto;
    border-radius: 4px;
    padding: 2px 4px;
}

@media (min-width: 1140px) {
    .hori-timeline .events .event-list {
        display: inline-block;
        width: 24%;
        padding-top: 45px;
    }

    .hori-timeline .events .event-list .event-date {
        top: -12px;
    }
}

.bg-soft-primary {
    background-color: rgba(64, 144, 203, .3) !important;
}

.bg-soft-success {
    background-color: rgba(71, 189, 154, .3) !important;
}

.bg-soft-danger {
    background-color: rgba(231, 76, 94, .3) !important;
}

.bg-soft-warning {
    background-color: rgba(249, 213, 112, .3) !important;
}

.card {
    border: none;
    margin-bottom: 24px;
    -webkit-box-shadow: 0 0 13px 0 rgba(236, 236, 241, .44);
    box-shadow: 0 0 13px 0 rgba(236, 236, 241, .44);
}
.scroll-timeline{
    overflow: auto;
    white-space: nowrap;
}
</style>
<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <div class="row">
            <div class="col-lg-12">
                <div class="card">
                    <div class="card-body">
                        <b-tabs content-class="p-3 text-muted">
                            <b-tab active class="border-0">
                                <template v-slot:title>
                                    <span class="d-inline-block d-sm-none">
                                        <i class="fas fa-home"></i>
                                    </span>
                                    <span class="d-none d-sm-inline-block">Detail penggunaan</span>
                                </template>
                                <detail-penggunaan></detail-penggunaan>
                                <detail-surat-tugas></detail-surat-tugas>
                            </b-tab>
                        </b-tabs>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
</template>
